import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Success from "./Components/Success";
import Roundslider from "./Components/Roundslider";
import Roted3d from "./Components/Roted3d";
import Footer from "./Components/Footer.jsx";
import Headernav1 from "./Components/Headernav1/Headernav1";
import Choice from "./Components/Choice/Choice";
import Next from "./Components/Next/Next";
import Ntarpay from "./Pages/Home/Ntarpay/Ntarpay";
import Bbpscroll from "./Pages/Home/Ntarpay/Bbpscroll";
import Layout from "./Components/Layout/Layout";
import Animation from "./Components/Animation/Animation";
import Comingsoon from "./Pages/Home/Comingsoon/Comingsoon";
import Aboutus from "./Pages/Aboutus/Aboutus";
import Help from "./Components/Help/Help.jsx";
import ContactUs from "./Pages/Contact/ContactUs.jsx";
import GrievancePolicy from "./Pages/GrievancePolicy/GrievancePolicy.jsx";
import Cookiepolicy from "./Pages/Cookiepolicy/Cookiepolicy.jsx";
import TermsCondfaq from "./Pages/Terms&Condition/Termscondfaq.jsx";
import Termsandconditions from "./Pages/Terms&Condition/Termsandconditions.jsx";
import RentalFaq from "./Pages/Terms&Condition/RentalFaq.jsx";
import Rechargebillfaq from "./Pages/Terms&Condition/Rechargebillfaq.jsx";
import Creditcardfaq from "./Pages/Terms&Condition/Creditcardfaq.jsx";
import Expensemanfaq from "./Pages/Terms&Condition/Expensemanfaq.jsx";
import Privacypolicy from "./Pages/privacypolicy/Privacypolicy.jsx";
import Faqsection from "./Pages/privacypolicy/Faqsection.jsx";
import FAQ from "./Pages/Helpus/FAQ.jsx";
import Helpfaq from "./Pages/Helpus/Helpfaq.jsx";
import GlobalContextProvider from "./Components/GlobalContext/GlobalContext.jsx";
import Bookcom from "./Components/Bookcom/Bookcom.js";


function App() {
  return (
    <BrowserRouter>
      <GlobalContextProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Headernav1" element={<Headernav1 />} />
          <Route path="/Choice" element={<Choice />} />
          <Route path="/Next" element={<Next />} />
          <Route path="/Ntarpay" element={<Ntarpay />} />
          <Route path="/Animation" element={<Animation />} />
          <Route path="/Comingsoon" element={<Comingsoon />} />
          <Route path="/Aboutus" element={<Aboutus />} />
          <Route path="/Help" element={<Help />} />
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/GrievancePolicy" element={<GrievancePolicy />} />
          <Route path="/Footer" element={<Footer />} />
          <Route path="/Cookiepolicy" element={<Cookiepolicy />} />
          <Route path="/TermsCondfaq" element={<TermsCondfaq />} />
          <Route path="/Termsandconditions" element={<Termsandconditions />} />
          <Route path="/RentalFaq" element={<RentalFaq />} />
          <Route path="/Rechargebillfaq" element={<Rechargebillfaq />} />
          <Route path="/Creditcardfaq" element={<Creditcardfaq />} />
          <Route path="/Expensemanfaq" element={<Expensemanfaq />} />
          <Route path="/Privacypolicy" element={<Privacypolicy />} />
          <Route path="/Faqsection" element={<Faqsection/>} />
          <Route path="/FAQ" element={<FAQ/>} />
          <Route path="/Helpfaq" element={<Helpfaq/>} />
          
          {/* <Route path="/" element={<Bookcom/>} /> */}
       

          {/* <Route path="/Bbpscroll " element={<Bbpscroll />} /> */}
          {/* <Route path="/" element={<Footer/>} /> */}
        </Routes>
      </Layout>
      </GlobalContextProvider>
    </BrowserRouter>
  );
}

export default App;
